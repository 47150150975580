/*
	Name:				mixins.less
	Description:        Core mixins for the theme styles.
	Version:            1.0.0
	Author:             Garrison Hughes
*/
.columnWidth(@value) {
	-webkit-column-width: @value;
	-moz-column-width: @value;
	column-width: @value;
}
.columnCount(@value) {
	-webkit-column-count: @value;
	-moz-column-count: @value;
	column-count: @value;
}
.columnGap(@value) {
	-webkit-column-gap: @value;
	-moz-column-gap: @value;
	column-gap: @value;
}
.columnFill(@value) {
	-webkit-column-fill: @value;
	-moz-column-fill: @value;
	column-fill: @value;
}
.columnBreakInside(@value) {
	-webkit-column-break-inside: @value;
	page-break-inside: @value;
	break-inside: @value;
}
/* Gradient */
.gradient(@color, @start, @stop) {
	background: @color;
	background: -webkit-gradient(linear, left bottom, left top, color-stop(0, @start), color-stop(1, @stop));
	background: -ms-linear-gradient(bottom, @start, @stop);
	background: -moz-linear-gradient(center bottom, @start 0%, @stop 100%);
	background: -o-linear-gradient(@stop, @start);
	filter: e(
		%(
			"progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",
			@stop,
			@start
		)
	);
}

/* Border Radius */
.borderRadius(@value) {
	-webkit-border-radius: @value;
	-moz-border-radius: @value;
	border-radius: @value;
}

.borderTopLeftRadius(@value) {
	-webkit-border-top-left-radius: @value;
	-moz-border-top-left-radius: @value;
	border-top-left-radius: @value;
}

.borderBottomLeftRadius(@value) {
	-webkit-border-bottom-left-radius: @value;
	-moz-border-bottom-left-radius: @value;
	border-bottom-left-radius: @value;
}
.borderBottomRightRadius(@value) {
	-webkit-border-bottom-right-radius: @value;
	-moz-border-bottom-right-radius: @value;
	border-bottom-right-radius: @value;
}

.borderTopRightRadius(@value) {
	-webkit-border-top-right-radius: @value;
	-moz-border-top-right-radius: @value;
	border-top-right-radius: @value;
}

/* Box-Shadow */
.boxShadow(@value) {
	-webkit-box-shadow: @value;
	-moz-box-shadow: @value;
	-ms-box-shadow: @value;
	box-shadow: @value;
}

/* Box Sizing */
.boxSizing(@value) {
	-webkit-box-sizing: @value;
	-moz-box-sizing: @value;
	box-sizing: @value;
}

/* Text Size adjust */
.textSizeAdjust(@value) {
	-ms-text-size-adjust: @value;
	-webkit-text-size-adjust: @value;
}
/* Animation Properties */
.animationFillMode(@value) {
	-webkit-animation-fill-mode: @value;
	-moz-animation-fill-mode: @value;
	animation-fill-mode: @value;
}

.animationDelay(@value) {
	-webkit-animation-delay: @value;
	-moz-animation-delay: @value;
	animation-delay: @value;
}

.animationDirection(@value) {
	-webkit-animation-direction: @value !important;
	animation-direction: @value !important;
}

.animationDuration(@value) {
	-webkit-animation-duration: @value;
	animation-duration: @value;
}

.animationTiming(@value) {
	-webkit-animation-timing-function: @value;
	animation-timing-function: @value;
}

.animationWithName(@value) {
	-webkit-animation-name: @value;
	animation-name: @value;
}

.animation(@value) {
	-webkit-animation: @value;
	animation: @value;
}

/* Transform Properties */
.transform(@value) {
	-webkit-transform: @value;
	-moz-transform: @value;
	-o-transform: @value;
	-ms-transform: @value;
	transform: @value;
}

.transformOrigin(@value) {
	-webkit-transform-origin: @value;
	-moz-transform-origin: @value;
	-o-transform-origin: @value;
	-ms-transform-origin: @value;
	transform-origin: @value;
}

/* Transition Properties */
.transition(@value1,@value2:X,...) {
	@value: ~`"@{arguments}".replace(/[\[\]]|\,\sX/g, '') `;

	-webkit-transition: @value;
	-moz-transition: @value;
	-ms-transition: @value;
	-o-transition: @value;
	transition: @value;
}

.transitionProperty(...) {
	@props: ~`"@{arguments}".replace(/[\[\]]/g, '') `;
	-webkit-transition-property: @props;
	-moz-transition-property: @props;
	-o-transition-property: @props;
	-ms-transition-property: @props;
	transition-property: @props;
}

.transitionDuration(@value) {
	-webkit-transition-duration: @value;
	-moz-transition-property: @value;
	-o-transition-property: @value;
	-ms-transition-property: @value;
	transition-duration: @value;
}

.transitionTiming(@value) {
	-webkit-transition-timing-function: @value;
	-moz-transition-timing-function: @value;
	-o-transition-timing-function: @value;
	transition-timing-function: @value;
}

/* Opacity */
.opacity(@value) {
	-webkit-opacity: @value;
	opacity: @value;
}
.appearance(@value) {
	-webkit-appearance: @value; /*Safari/Chrome*/
	-moz-appearance: @value; /*Firefox*/
	-ms-appearance: @value; /*IE*/
	-o-appearance: @value; /*Opera*/
	appearance: @value;
}

/* Flexbox */
.displayFlex() {
	display: block; /* IE < 10, Opera *Presto* Desktop (Now dead) */
	display: -webkit-box; /* Safari 3.1 - 6, Chrome < 21 (2009 Spec), UCBrowser Android */
	display: -moz-box; /* Firefox 2 - 27 (2009 Spec), UCMini Android */
	display: -ms-flexbox; /* IE10 (2012 Syntax) */
	display: -webkit-flex; /* Safari 6.1 - 8, Android < 4.4, BB < 10, Chrome 21 - 28 */
	display: flex; /* Edge 12+, Firefox 28+, Blink, Safari 9+, Opera Mini 8+ */
}
.flex(@value) {
	-webkit-box-flex: @value;
	-moz-box-flex: @value;
	-webkit-flex: @value;
	-ms-flex: @value;
	flex: @value;
}
.flexWrap(@value) {
	-webkit-flex-wrap: @value;
	-moz-flex-wrap: @value;
	-ms-flex-wrap: @value;
	flex-wrap: @value;
}
.flexDirection(@value) {
	-webkit-flex-direction: @value;
	-moz-flex-direction: @value;
	-ms-flex-direction: @value;
	flex-direction: @value;
}
.flexFlow(@value) {
	-webkit-flex-flow: @value;
	-moz-flex-flow: @value;
	-ms-flex-flow: @value;
	flex-flow: @value;
}
.flexJustifyContent(@value) {
	-webkit-justify-content: @value;
	-moz-justify-content: @value;
	-ms-justify-content: @value;
	justify-content: @value;
	-ms-flex-pack: @value;
}
.flexAlignContent(@value) {
	-webkit-align-content: @value;
	-moz-align-content: @value;
	-ms-align-content: @value;
	align-content: @value;
}
.flexAlignItems(@value) {
	-webkit-align-items: @value;
	-moz-align-items: @value;
	-ms-align-items: @value;
	align-items: @value;
}
.flexAlignSelf(@value) {
	-webkit-align-self: @value;
	-moz-align-self: @value;
	-ms-align-self: @value;
	align-self: @value;
}
.flexBasis(@value) {
	-webkit-flex-basis: @value;
	-moz-flex-basis: @value;
	flex-basis: @value;
}
.flexGrow(@value) {
	-webkit-flex-grow: @value;
	-moz-flex-grow: @value;
	flex-grow: @value;
}
