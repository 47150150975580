/*
	Name:				posts.less
	Description:        Post styles for the theme.
	Version:            1.0.0
	Author:             Garrison Hughes
*/
@import 'fontawesome/fontawesome';
@import 'fontawesome/regular';
@import 'fontawesome/solid';

@import (reference) 'variables';
@import (reference) 'mixins';

/* Post Core structure elements
----------------------------*/

// ALL Posts
.newsItemTitle a {
  text-decoration: none;
  color: @black;
}

// Post on posts page
.post_container {
  max-width: 22.625rem;
  width: 100%;
  background: @white;
  position: relative;
  margin: 2rem auto;

  // News/Blog page
  &--news {
    .transition(all 0.5s ease-out);
    .opacity(0);

    &.show {
      .opacity(1);
    }
  }

  //For archived posts
  &--archive {
    background: @gallery;
    margin: 2rem 0;
  }

  .newItem_img {
    max-height: 12.5rem;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
    }
  }

  .newsItemContent {
    padding: 0 1rem 2.75rem;
  }

  .newsItemExcerpt {
    font-size: 1.125rem;

    a {
      text-decoration: none;
      color: @black;
    }
  }
  .read_more_btn {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
  }
}

// Load More Btn
.btn_big_gold_reverse {
  font-family: 'prohibition', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  color: @black;
  letter-spacing: 0.89px;
  text-align: center;
  line-height: 18px;
  background: @gallery;
  border: 2px solid @gold !important;
  padding: 8px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}
.gold_center {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    display: block;
    border: 1px solid @gold;
    width: 100%;
    top: 50%;
    margin: 0 auto;
  }
}

// Post on sidebar
.post_sidebar {
  max-width: 350px;
  width: 100%;

  .newItem_img img {
    display: block;
    width: 100%;
  }

  .arrow_link {
    text-decoration: none;
    color: @black;
    position: relative;
    display: block;
    height: 30px;
    margin-bottom: 20px;

    &::before {
      .fa-icon;
      .fas;
      content: @fa-var-arrow-right;
      // margin-left: 5px;
      color: @black;
      border: 2px solid @gold;
      font-size: 10px;
      padding: 3px;
      border-radius: 50%;
      position: absolute;
      top: 20%;
      left: 0;
    }
    &::after {
      content: '';

      border-top: 1px dotted @silver;
      width: 90%;
      position: absolute;
      top: 50%;
      right: 0;
    }
  }
}

/* Social Share Component
*/
.social_container {
  display: inline-block;

  .component_social_share_text {
    padding: 0 20px;
  }
  .social_links {
    .flexAlignItems(center);
    position: relative;
    .flex(1 0 0);
    a {
      position: relative;
      z-index: 8;
      color: @white;
      padding: 0 15px;
      font-size: 1.25rem;
    }

    .social_links_bg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      .opacity(0.2);
    }
  }
}

/* Component Events
*/
.event_block {
  max-width: 362px;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin: 0 auto;

  a {
    text-decoration: none;
    color: @black;
  }
}

.flex.search {
  .flexJustifyContent(space-between);
}

@media only screen and (max-width: 756px) {
  .post_container {
    margin: 16px auto;
  }
  /* Social Share Component
	*/
  .social_container {
    .component_social_share_text {
      padding: 0 15px;
    }
    .social_links {
      a {
        padding: 0 10px;
      }
    }
  }
}
