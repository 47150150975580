/*
	Name:				footer.less
	Description:        Footer specific styles for the theme.
	Version:            1.0.0
	Author:             Garrison Hughes
*/

@import 'fontawesome/brands';

@import (reference) 'variables';
@import (reference) 'mixins';

footer {
	color: white;

	.flex.content {
		.flexJustifyContent(space-between);
	}

	.footer_logo {
		max-width: 225px;
		width: 100%;
		margin-right: 80px;

		img {
			display: block;
			width: 100%;
		}
	}

	.footer_info {
		// max-width: 852px;
		.flex(1);
		width: 100%;
		// .displayFlex();
		// .flexDirection(column);
		// .flexJustifyContent(space-between);

		.info_row {
			padding: 16px 0;
		}
		.info_row.flex {
			.flexJustifyContent(space-between);
			.flexAlignItems(flex-start);
		}

		.address {
			font-size: 1.25rem;
			line-height: 24px;
			margin: 0;
			// padding: 16px 0;

			p {
				margin: 0;
			}
			.gold {
				color: @gold;
				font-weight: 700;
			}
		}
	}

	// Footer Menu CTA
	.navFooterCTA {
		.menu-item a {
			font-family: 'prohibition', sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 1rem;
			color: @white;
			letter-spacing: 0.89px;
			text-decoration: none;
			text-align: center;
			line-height: 18px;
			background: @charcoal;
			padding: 8px;
			border: 2px solid @gold;
			margin-left: 16px;
			display: block;
			.transition(all 1s ease);

			&:hover {
				// border: 1px solid @charcoal;
				box-shadow: inset 0 0 0 1px @charcoal, inset 0 0 0 2px #fff, inset 0 0 0 3px @charcoal,
					inset 0 0 0 4px @gold, inset 0 0 0 4px @charcoal, inset 0 0 0 5px #fff;
			}
		}
		.menu-item:first-child a {
			margin-left: 0;
		}
		.menu-item-type-custom a {
			color: @black;
			background: @gold;
			.transition(all 1s ease);

			&:hover {
				box-shadow: inset 0 0 0 1px #fff, inset 0 0 0 2px @gold, inset 0 0 0 3px @black, inset 0 0 0 4px @gold,
					inset 0 0 0 5px @white, inset 0 0 0 6px @gold;
			}
		}
	}

	// Footer Main Menu
	.navFooter {
		border-top: 1px solid @gold;
		border-bottom: 1px solid @gold;
		.flexJustifyContent(space-between);

		.menu-item a {
			font-size: 1.125rem;
			line-height: 20px;
			font-weight: 700;
			font-family: 'mrs-eaves-xl-serif', serif;
			font-style: normal;
			text-decoration: none;
			color: @white;
			padding: 24px 0;
			letter-spacing: 0;
			display: block;
		}
	}

	// Social Icons
	ul.social-icons {
		list-style: none;
		padding-inline-start: 0;
		margin-block-start: 0;
		.displayFlex();

		li a {
			color: white;
			font-size: 1.5rem;
			padding: 0;
			margin: 0 20px 0 0;
			text-decoration: none;
		}
	}
	// Footer Menu Policy
	.navFooterPolicy {
		.menu-item {
			border-left: 1px solid @gold;

			&:first-child {
				border-left: none;
			}

			&:last-child a {
				padding: 0 0 0 16px;
			}
			a {
				font-size: 1rem;
				line-height: 20px;
				text-decoration: none;
				color: @silver;
				letter-spacing: 0;
				display: block;
				padding: 0 16px;
			}
		}
	}

	// Lower Footer
	.footer_secondary {
		.flex.content {
			.flexAlignItems(center);
		}

		.logos {
			width: 225px;
			margin-right: 80px;
			.displayFlex();
			.flexJustifyContent(space-around);

			#charityNavigator {
				max-width: 93px;
				width: 100%;
			}

			#sealTransparency {
				max-width: 72px;
				width: 100%;
			}
		}
		.disclaimer {
			// max-width: 852px;
			width: 100%;
			.flex(1);

			p {
				font-size: 14px;
				color: @silver;
			}
		}
	}
}

/* Media Queries
----------------------------*/

@media only screen and (max-width: 1156px) {
	footer {
		.footer_logo {
			max-width: 200px;
			margin-right: 60px;
		}
		// Footer Main Menu
		.navFooter {
			.menu-item a {
				font-size: 1rem;
			}
		}

		.footer_secondary {
			.logos {
				width: 200px;
				margin-right: 60px;
			}
		}
	}
}

// mobile stuff
@media only screen and (max-width: 978px) {
	footer {
		.footer_logo {
			max-width: 175px;
			margin-right: 40px;
		}
		// Footer Main Menu
		.navFooter {
			.menu-item a {
				font-size: 1rem;
			}
		}

		.footer_secondary {
			.logos {
				width: 175px;
				margin-right: 40px;
			}
		}
	}
}

@media only screen and (max-width: 900px) {
	footer {
		.flex.content {
			.flexJustifyContent(center);
			.flexDirection(column);
		}

		.footer_logo {
			max-width: 175px;
			margin: 0 auto;
			padding-bottom: 12px;
		}
		.footer_info {
			.flex(1 1 auto);
		}
		// Lower Footer
		.footer_secondary {
			.disclaimer {
				.flex(1 1 auto);
			}
		}
	}
}

@media only screen and (max-width: 720px) {
	footer {
		// Footer Main Menu
		.navFooter {
			.flexDirection(column);
			.flexJustifyContent(center);
			padding: 20px 0;

			.menu-item a {
				font-size: 1.125rem;
				padding: 10px 0;
			}
		}

		.footer_info {
			.address {
				margin: 0 0 20px;
			}
			.info_row.flex {
				.flexJustifyContent(center);
				.flexDirection(column);
				.flexAlignItems(center);
				text-align: center;
			}
		}
	}
}

@media only screen and (max-width: 480px) {
	footer {
		// Footer Menu Policy
		.navFooterPolicy {
			.flexJustifyContent(center);
			.flexDirection(column);

			.menu-item {
				border-left: none;

				&:first-child {
					border-left: none;
				}

				&:last-child a {
					padding: 16px 0;

					&::after {
						content: '';
						height: 0;
					}
				}
				a {
					font-size: 1rem;
					line-height: 20px;
					text-decoration: none;
					color: @silver;
					letter-spacing: 0;
					display: block;
					padding: 16px 0;
					position: relative;

					&::after {
						content: '';
						height: 1px;
						width: 100%;
						max-width: 20px;
						position: absolute;
						bottom: 0;
						background: @gold;
						.opacity(1);
						left: 50%;
						.transform(translate3d(-50%, 0, 0));
					}
				}
			}
		}
	}
}
