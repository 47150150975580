/*
	Name:				woocommerce.less
	Description:        Woocomerce overide styles for the theme.
	Version:            1.0.0
	Author:             Garrison Hughes
*/

@import (reference) 'variables';
@import (reference) 'mixins';

/* WooCommerce Override structure elements
----------------------------*/

.woocommerce-error,
.woocommerce-info,
.woocommerce-message {
    font-size: 1rem;
}

.product {
    .imagewrapper {
        max-width: 254px;
        max-height: 254px;
        text-align: right;
        overflow: hidden;
        position: relative;
        background: rgb(242, 241, 241);
        background: -moz-radial-gradient(circle, rgba(242, 241, 241, 0) 6%, rgba(242, 241, 241, 1) 100%);
        background: -webkit-radial-gradient(circle, rgba(242, 241, 241, 0) 6%, rgba(242, 241, 241, 1) 100%);
        background: radial-gradient(circle, rgba(242, 241, 241, 0) 6%, rgba(242, 241, 241, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(
				startColorstr="#f2f1f1",
				endColorstr="#f2f1f1",
				GradientType=1
			);

        img {
            mix-blend-mode: multiply;
        }
    }

    .product_title {
        font-size: 20px;
        color: @black;
        line-height: 24px;
    }
    .price {
        del {
            padding: 0 20px 0 0;
        }
        .woocommerce-Price-amount.amount {
            font-family: 'prohibition', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            color: @black !important;
        }
    }
    .highlight::after {
        top: 0;
    }
    .sale {
        font-family: 'prohibition', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        color: @black;
        position: relative;
        top: 0;
        right: 0;
        z-index: 9;

        &::after {
            top: 0;
        }
    }
}

// Single Products
.single-product {
    .product_single_image {
        max-width: 451px;
        max-height: 451px;
        text-align: right;
        overflow: hidden;
        position: relative;
        background: rgb(242, 241, 241);
        background: -moz-radial-gradient(circle, rgba(242, 241, 241, 0) 6%, rgba(242, 241, 241, 1) 100%);
        background: -webkit-radial-gradient(circle, rgba(242, 241, 241, 0) 6%, rgba(242, 241, 241, 1) 100%);
        background: radial-gradient(circle, rgba(242, 241, 241, 0) 6%, rgba(242, 241, 241, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(
				startColorstr="#f2f1f1",
				endColorstr="#f2f1f1",
				GradientType=1
			);

        img {
            mix-blend-mode: multiply;
        }
    }
    span.highlight {
        position: absolute;
    }
    span.onsale {
        font-family: 'prohibition', sans-serif;
        font-style: normal;
        font-weight: 400 !important;
        font-size: 24px !important;
        color: @black !important;
        position: relative !important;
        background: none !important;
        min-height: inherit !important;
        min-width: inherit !important;
        left: 0 !important;
        top: 0 !important;
        right: 0 !important;
        line-height: normal !important;
        z-index: 9;

        &::after {
            top: 0;
        }
    }
}

.woocommerce a.button,
.woocommerce button.button,
.product button.button,
a.button.btn_big_gold,
.button.btn_big_gold {
    font-family: prohibition, sans-serif;
    font-size: 1.125rem !important;
    letter-spacing: 0.89px;
    line-height: 18px !important;
    background: @gold !important;
    color: @black !important;
    padding: 10px !important;
    font-style: normal;
    font-weight: 400 !important;
    .borderRadius(0) !important;

    &:hover {
        background: @gold !important;
        color: @black !important;
        box-shadow: inset 0 0 0 1px @gold, inset 0 0 0 2px #fff, inset 0 0 0 3px @gold, inset 0 0 0 4px #000, inset 0 0 0 5px @gold, inset 0 0 0 6px #fff;
    }
}

.woocommerce .quantity .qty {
    width: 3.631em;
    text-align: center;
    font-size: 1.125rem;
    line-height: 18px;
    .borderRadius(0);
    padding: 9px;
    border: 1px solid grey;
}

.stock,
.variations {
    font-family: 'prohibition', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}

.variations {
    font-size: 20px;
    color: @black !important;
}
.stock {
    color: @silver !important;
}

.woocommerce-message {
    border-top-color: @gold !important;

    &::before {
        color: @gold !important;
    }
}

/* Makes the featured single product image not clickable by changing the pointer. */
.woocommerce div.product div.images .woocommerce-product-gallery__wrapper {
    pointer-events: none;
}

//CART
.woocommerce-cart-form {
    thead {
        background: @gold;
    }
    .woocommerce-cart-form__contents,
    table.shop_table th {
        font-family: 'mr-eaves-xl-modern', sans-serif !important;
        font-style: normal;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.5rem;
    }
    table.cart img {
        max-width: 150px;
    }
}

//Checkout
form.checkout,
.woocommerce form.checkout_coupon,
.woocommerce form.login,
.woocommerce form.register,
.woocommerce-MyAccount-content {
    .borderRadius(0) !important;
    text-align: left;
    .col2-set .col-1,
    .col2-set .col-2 {
        background: @gallery;
        padding: 0 20px 20px;
    }
    .woocommerce-EditAccountForm {
        padding: 20px;
    }
    .form-row input.input-text,
    .woocommerce form .form-row textarea,
    .form-row .input-text {
        padding: 8px;
        .borderRadius(0);
        border: 1px solid silver;
    }
}

//form input active states
.woocommerce form .form-row.woocommerce-validated .select2-container,
.woocommerce form .form-row.woocommerce-validated input.input-text,
.woocommerce form .form-row.woocommerce-validated select {
    border-color: @gold !important;
}
.woocommerce-info {
    border-top-color: @gold !important;

    &::before {
        color: @gold !important;
    }
}
//form table
.woocommerce table.shop_table {
    .borderRadius(0) !important;
    thead {
        background: @gold;
    }
}
.woocommerce-checkout #payment {
    background: @gallery !important;
    .borderRadius(0) !important;
}

//MY ACCOUNT
.woocommerce-MyAccount-navigation ul {
    display: block;
    list-style: none;
    li.woocommerce-MyAccount-navigation-link {
        display: inline-block;
        padding: 5px;
    }
}

@media only screen and (min-width: 768px) {
    .woocommerce div.product div.summary {
        max-width: 600px;
        display: inline-block;
        vertical-align: top;
        padding-left: 40px;
        float: none !important;
        width: 100% !important;
    }
    .woocommerce div.product div.images {
        width: 100% !important;
        display: inline-block;
        float: none !important;
        max-width: 451px;
    }
}
