/*
	Name:				contact_us.less
	Description:        Contact Us Page / Blocks styles for the theme.
	Version:            1.0.0
	Author:             Garrison Hughes
*/
@import 'fontawesome/fontawesome';
@import 'fontawesome/regular';
@import 'fontawesome/light';
@import 'fontawesome/solid';

@import (reference) 'variables';
@import (reference) 'mixins';

/* Contact Us Core structure elements
----------------------------*/
/* Info Block Newsletter
*/
.contact_info_block {
	.flexJustifyContent(center);
	.info_block {
		max-width: 362px;
		width: 100%;
		position: relative;

		h5 {
			text-transform: uppercase;

			&.silver {
				color: @silver;
			}
		}
		span.gold {
			color: @gold;
		}

		&:first-of-type:after {
			content: '';
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			.opacity(.4);
			border-right: dotted 2px @silver;
		}
	}
}

/* Media Queries
----------------------------*/
@media only screen and (max-width: 764px) {
	/* Info Block Newsletter
    */
	.contact_info_block {
		.flexDirection(column);
		.flexAlignItems(center);
		.info_block {
			&:first-of-type:after {
				display: none;
			}
		}
	}
}
