/*
	Name:				navigation.less
	Description:        Navigation specific styles for the theme.
	Version:            1.0.0
	Author:             Garrison Hughes
*/
@import 'fontawesome/fontawesome';
@import 'fontawesome/regular';
@import 'fontawesome/light';

@import (reference) 'variables';
@import (reference) 'mixins';

#mobileMenuButton {
	display: none;
}
// Primary Main Menu CTA
.navMenuCTA {
	.displayFlex();
	.flexJustifyContent(flex-end);
	.flexAlignItems(center);
	list-style: none;
	margin: 0;
	padding: 0;

	.searchIcon {
		.displayFlex();
		color: @silver;
		font-size: 14px;
		font-weight: 700 !important;
		padding: 0 15px;

		.mobile {
			display: none;
		}
	}
	button {
		background: none;
		border: none;
		border-style: none;
		border-image: none;
		position: relative;
	}
	.main-menu-item {
		border-left: 1px solid @gold;
	}
	.menu-item-type-custom {
		border-left: none;
	}
	.main-menu-link {
		font-size: 1rem;
		line-height: 19px;
		font-weight: 700;
		color: @silver;
		text-decoration: none;
		text-transform: uppercase;
		padding: 0 16px;
		letter-spacing: 0;
	}
}

// Primary Main Menus White and Black
.navMenu {
	.displayFlex();
	.flexJustifyContent(flex-end);
	.flexAlignItems(center);
	list-style: none;
	margin: 0;
	padding: 0;
	height: 100%;

	.main-menu-item {
		list-style: none;
		position: relative;
		height: 100%;
		.displayFlex();
		.flexAlignItems(center);

		&::after {
			content: '';
			height: 8px;
			width: 100%;
			max-width: 80px;
			position: absolute;
			bottom: 0;
			background: @black;
			.opacity(0);
			left: 50%;
			.transform(translate3d(-50%, 0, 0));
		}

		&:hover::after {
			.opacity(1);
		}
		&.current-menu-item::after,
		&.current-page-ancestor::after {
			background: @gold !important;
			.opacity(1) !important;
		}

		&.show {
			max-height: 1000px !important; //May want to make this higher if menu is long for some reason
			.transform(translate3d(0, 0, 0));
			.transition(max-height .7s ease);
		}

		&.hide {
			max-height: 0;
			.transform(translate3d(0, 0, 0));
			.transition(max-height 0s ease);
		}
		// &:last-child .main-menu-link {
		// 	padding: 0 0 0 14px;
		// }
	}
	.main-menu-link {
		font-size: 1.125rem;
		line-height: 20px;
		font-weight: 700;
		font-family: 'mrs-eaves-xl-serif', serif;
		font-style: normal;
		color: @charcoal;
		text-decoration: none;
		padding: 14px;
		letter-spacing: 0;
	}

	.sub-menu {
		list-style: none;
		padding-inline-start: 0;
		width: 304px;
		margin: 0;
		background: @gold;
		position: absolute;
		top: 100%;
		left: 50%;
		text-align: center;
		.transform(translate3d(-50%, 0, 0));

		&.show {
			max-height: 1000px !important; //May want to make this higher if menu is long for some reason
			.transform(translate3d(-50%, 0, 0));
			.transition(max-height .7s ease);
		}

		&.hide {
			max-height: 0;
			overflow: hidden;
			.transform(translate3d(-50%, 0, 0));
			.transition(max-height 0s ease);
			padding: 0;
		}

		.sub-menu-item {
			.sub-menu-link {
				display: block;
				font-size: 1.125rem;
				line-height: 24px;
				text-decoration: none;
				color: @black;
				letter-spacing: 0;
				text-align: center;
				padding: 12px;
				.transition(all 1s ease);

				&:hover {
					background: @black;
					color: @white;
				}
			}
			&:first-child .sub-menu-link {
				padding: 18px 12px 12px;
			}
			&:last-child .sub-menu-link {
				padding: 12px 12px 18px;
			}
		}
	}

	.menu-item-has-children .sub-menu {
		max-height: 0;
		overflow: hidden;
		.transform(translate3d(-50%, 0, 0));
		.transition(max-height 0s ease);
		padding: 0;
	}
	.menu-item-has-children:not(.disable-hover):hover .sub-menu {
		max-height: 1000px !important; //May want to make this higher if menu is long for some reason
		.transform(translate3d(-50%, 0, 0));
		.transition(max-height .7s ease);
	}
}

#mainNavigation .content,
#mainNavigation_scroll .content {
	.flexJustifyContent(space-between);
	.flexAlignItems(center);

	// INTENDED FOR DONATE BUTTON
	.menu-item-depth-0.menu-item-type-custom {
		padding: 0 10px;
		.menu-link {
			font-family: 'prohibition', sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 1rem;
			color: @black;
			letter-spacing: 0.89px;
			text-align: center;
			line-height: 18px;
			background: @gold;
			padding: 8px;
			.transition(all 1s ease);

			&:hover {
				box-shadow: inset 0 0 0 1px @gold, inset 0 0 0 2px #fff, inset 0 0 0 3px @gold, inset 0 0 0 4px #000,
					inset 0 0 0 5px @gold, inset 0 0 0 6px #fff;
			}
		}
	}
}

#mainNavigation {
	background: @white;

	&.shadow {
		-webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.75);
		box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.75);
	}

	.content {
		height: 120px;
	}
	.main_menu_container {
		.flexAlignSelf(flex-end);
	}
	#menu-main-menu {
		height: 70px;
		.main-menu-item {
			.flexAlignItems(flex-start);
		}
	}
	.logo {
		width: 264px;
		height: 79px;
		background-image: url('../images/mlf-horz-main-nav-white.svg');
		background-repeat: no-repeat;
		background-size: contain;
	}
}
#mainNavigation_scroll {
	background: @black;
	position: fixed;
	top: -150px;
	width: 100%;
	.transition(all .75s ease);

	.content {
		height: 80px;
	}

	.logo_reverse {
		width: 163px;
		height: 48px;
		background-image: url('../images/mlf-horz-main-nav-reverse.svg');
		background-repeat: no-repeat;
		background-size: contain;
	}

	.main-menu-link {
		font-size: 1rem;
		line-height: 19px;
		color: @white;
		padding: 0 10px;
		letter-spacing: 0;
	}
}
/* Sidebar Navigation on Subpages
----------------------------*/
.side-bar__navigation {
	.side-bar__navigation_heading a {
		text-decoration: none;
		color: @black;
	}

	.navigation__side {
		list-style: none;
		margin-inline-start: 0;
		padding-inline-start: 0;
		padding-left: 0;
		margin-left: 0;

		li {
			padding: 16px 0;
			position: relative;

			a {
				text-decoration: none;
				color: @silver;
				font-size: 1.125rem;
				letter-spacing: 0;
				line-height: 24px;
			}

			&:after {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				border-bottom: 1px @silver dotted;
				content: '';
				.opacity(.5);
			}
			&.current_page_item {
				a {
					color: @black;
				}
			}
			&:last-child::after {
				border-bottom: none;
			}
		}
	}
}

/* Search Overlay
----------------------------*/
#search-overlay {
	display: none;
	background-color: @gold;
	position: fixed;
	top: 0;
	height: 100vh;
	width: 100%;
	z-index: 9999;

	.search-text {
		text-align: right;
		font-size: 1.25rem;
		padding: 20px;
		color: @black;
	}

	.search-container {
		margin: 0 auto;
		width: 90%;
		max-width: 800px;
		position: relative;
		z-index: 9999;
		top: 40%;
		.transform(translate3d(0, -40%, 0));

		#site-search,
		#searchform-overlay {
			width: 100%;
			position: relative;
			display: flex;
			align-items: center;
			label.hidden {
				position: absolute;
				left: -10000px;
				top: auto;
				width: 1px;
				height: 1px;
				overflow: hidden;
			}
			.search-field {
				display: block;
				margin: 0;
				background-color: @gold;
				border: 2px solid @white !important;
				border-radius: 0;
				width: 100%;
				padding: 1.25rem !important;
				font-size: 1.125rem;
				line-height: 20px;
				font-weight: 700;
				font-family: 'mrs-eaves-xl-serif', serif;
				font-style: normal;
				color: @black;

				&::placeholder {
					/* Chrome, Firefox, Opera, Safari 10.1+ */
					color: @charcoal;
					opacity: 1; /* Firefox */
				}

				&:-ms-input-placeholder {
					/* Internet Explorer 10-11 */
					color: @charcoal;
				}

				&::-ms-input-placeholder {
					/* Microsoft Edge */
					color: @charcoal;
				}
			}

			button {
				background: @black;
				border-style: none;
				border-image: none;
				-webkit-appearance: none;
				padding: 15px;
				border: none;
				margin: 0 10px;
				font-family: 'prohibition', sans-serif;
				font-size: 18px;
				letter-spacing: 1px;
				text-align: center;
				line-height: 18px;
				position: absolute;
				right: 0;
				top: 12%;
				.transform(translate3d(0, 0, 0));
				color: @white;

				&:focus {
					outline: none;
					outline-color: none;
					outline-style: none;
					outline-width: 0;
				}
			}

			#search-submit {
				padding: 1.25rem;
			}
		}
	}

	&.activate {
		display: block;
	}
}

// PREV AND NEXT NAV
.prev-next-posts {
	.prev-posts-link {
		margin-right: 40px;
	}
	.prev-posts-link,
	.next-posts-link {
		margin-bottom: 1em;
	}
}

/* Media Queries
----------------------------*/
@media only screen and (max-width: 1440px) {
	.navMenu {
		//last nav items submenu position to prevent overflow
		.menu-item-has-children:not(.disable-hover):last-child:hover .sub-menu {
			.transform(translate3d(-70%, 0, 0));
		}
	}
}
@media only screen and (max-width: 1280px) {
	.navMenu {
		//last nav items submenu position to prevent overflow
		.menu-item-has-children:not(.disable-hover):last-child:hover .sub-menu {
			.transform(translate3d(-75%, 0, 0));
		}
	}
	#mainNavigation {
		.logo {
			width: 225px;
			height: 60px;
		}
		.main-menu-link {
			font-size: 1.1rem;
			line-height: 20px;
			padding: 14px 10px;
		}
	}
	#mainNavigation_scroll {
		.main-menu-link {
			font-size: .9rem;
			padding: 0 10px;
		}
	}
}

@media only screen and (max-width: 1156px) {
	.navMenu {
		//last nav items submenu position to prevent overflow
		.menu-item-has-children:not(.disable-hover):last-child:hover .sub-menu {
			.transform(translate3d(-80%, 0, 0));
		}
	}
	#mainNavigation {
		.logo {
			width: 200px;
			height: 60px;
		}
		.main-menu-link {
			font-size: 1rem;
		}
	}
	#mainNavigation_scroll {
		.main-menu-link {
			font-size: .8rem;
			padding: 0 8px;
		}
		// .content .menu-item-type-custom .menu-link {
		// 	font-size: .8rem;
		// 	padding: 5px;
		// }
	}
}

// mobile stuff
@media only screen and (max-width: 978px) {
	#mainNavigation_scroll {
		display: none;
	}
	#mainNavigation {
		// 	display: block;
		// 	padding: 0;
		max-height: 100vh;
		overflow-y: auto;
		overflow-x: hidden;
		position: fixed;
		top: 0;
		width: 100%;

		.content {
			height: 100%;
			padding: 0;
			.displayFlex();
			.flexDirection(column);
		}
		.container.flex {
			height: 72px;
			width: 100%;
			.flexAlignItems(center);
			.flexJustifyContent(space-between);
			padding: 0 20px;
			z-index: 100;
			background: @white;

			.logo {
				width: 160px;
				height: 48px;
			}

			#mobileMenuButton {
				font-size: 26px;
				line-height: 26px;
				color: black;
				cursor: pointer;
				text-align: center;
				display: block;

				&.close-menu::after {
					.fa-icon;
					.fal;
					content: @fa-var-times;
					color: @silver;
				}
				&.open-menu::after {
					.fa-icon;
					.fal;
					content: @fa-var-bars;
					color: @silver;
				}

				&.hide {
					display: none;
				}
			}
		}
		.navMenu .main-menu-item::after {
			height: 0;
		}
		.main_menu_container {
			.displayFlex();
			.flexDirection(column-reverse);
			.flexAlignSelf(flex-start);
			width: 100%;
			overflow: hidden;
			max-height: 0;
			.transform(translate3d(0, -20px, 0));
			.transition(max-height 0s ease);

			&.show {
				max-height: 100% !important; //May want to make this higher if menu is long for some reason
				.transform(translate3d(0, 0, 0));
				.transition(max-height .7s ease);
			}

			&.hide {
				max-height: 0;
				.transform(translate3d(0, -20px, 0));
				.transition(max-height 0s ease);
			}
		}
		#menu-main-menu {
			.flexDirection(column);
			.flexAlignItems(flex-start);
			height: 100%;
			background: @gold;

			.main-menu-item {
				width: 100%;
				display: block;
				height: auto;

				.sub-menu {
					position: relative;
					background: @charcoal;
					padding: 0;
					width: 100%;
					left: 0;
					top: 0;
					text-align: left;
					.transform(translate3d(0%, 0, 0));

					&.show {
						max-height: 1000px !important; //May want to make this higher if menu is long for some reason0
						.transform(translate3d(0, 0, 0));
						.transition(max-height .7s ease);
						overflow: visible;
					}

					&.hide {
						max-height: 0;
						overflow: hidden;
						.transform(translate3d(0, 0, 0));
						.transition(max-height 0s ease);
						padding: 0;
					}
					.sub-menu-item .sub-menu-link {
						text-align: left;
						padding: 12px 20px;
						color: @white;
					}
				}
			}
			.main-menu-link {
				padding: 16px 20px;
				display: inline-block;
				width: 90%;
				font-size: 1.5rem;
			}
			.menu-item-has-children > span.menu-items-btn {
				cursor: pointer;
				display: inline-block;
				width: 28px;
				height: 28px;
				background: @white;
				border: solid 1px @white;
				position: absolute;
				top: 12px;
				right: 20px;
				text-align: center;
				.transition(all, .25s, ease);

				&:after {
					.fa-icon;
					.fal;
					content: @fa-var-plus;
					font-size: 1.5rem;
					line-height: 1.65rem;
					position: relative;
					color: @silver;

					.transition(all, .5s ease);
				}

				&.close {
					background: @gold;
				}
				&.close:after {
					.transform(rotate(45deg));
				}
				&.hide {
					width: 0 !important;
				}
			}
		}
		#menu-main-menu-cta {
			.flexJustifyContent(flex-start);
			background: @gold;
			padding: 20px;

			.main-menu-item {
				padding: 0 10px 0 0;
			}
			.menu-item-type-custom {
				padding: 0;

				.main-menu-link {
					color: @black;
					background: @white;
					border: 2px solid @white;

					&:hover {
						box-shadow: inset 0 0 0 1px @gold, inset 0 0 0 2px #fff, inset 0 0 0 3px @gold,
							inset 0 0 0 4px #000, inset 0 0 0 5px @gold, inset 0 0 0 6px #fff;
					}
				}
			}
			.main-menu-link {
				font-family: 'prohibition', sans-serif;
				font-style: normal;
				font-weight: 400;
				font-size: 1rem;
				color: @black;
				letter-spacing: 0.89px;
				text-align: center;
				line-height: 18px;
				background: @gold;
				padding: 8px;
				border: 2px solid @white;
				.transition(all 1s ease);

				&:hover {
					box-shadow: inset 0 0 0 1px @gold, inset 0 0 0 2px #fff, inset 0 0 0 3px @gold, inset 0 0 0 4px #000,
						inset 0 0 0 5px @gold, inset 0 0 0 6px #fff;
				}
			}
		}
		.navMenuCTA .searchIcon {
			display: none;
		}
	}
}
