/*
	Name:				content_blocks.less
	Description:        Content Blocks styles for the theme.
	Version:            1.0.0
	Author:             Garrison Hughes
*/
@import 'fontawesome/fontawesome';
@import 'fontawesome/regular';
@import 'fontawesome/light';
@import 'fontawesome/solid';

@import (reference) 'variables';
@import (reference) 'mixins';

/* Core structure elements
----------------------------*/
/* WYSIWYG Block 
*/
.wysiwyg_blocks {
	//keep images responsive
	img {
		display: inline-block;
		max-width: 100%;
		height: auto;
	}
}

/* Block with ice parallax bg 
*/
.block_relative {
	position: relative;
	.relative_inner {
		position: relative;
		z-index: 6;
	}
	.parallax_bg {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		background-attachment: fixed;
		background-position: center;
	}
}

/* Form Block Newsletter
*/
.form_blocks {
	.flex {
		.flexAlignItems(center);

		h3 {
			.flex(1);
			min-width: 200px;
		}
	}
	#gform_wrapper_1 {
		form,
		.gform_fields {
			// .displayFlex();
			// .flexAlignItems(flex-start);
			div.validation_error {
				border: none !important;
				position: absolute;
				top: -15px;
				padding: 0 !important;
			}
			.gform_body,
			.gform_fields {
				&:focus {
					outline: none !important;
				}
			}

			ul li.gfield {
				margin-top: 0;
				padding-top: 8px;
				padding-bottom: 8px;
				position: relative;
				.ginput_container {
					.displayFlex();
				}

				span {
					padding-top: 0 !important;
					// width: 225px;
					.flex(1 0 0);
					height: 100%;
				}
				input {
					border: none;
					width: 225px !important;
					margin: 0;
					height: 40px !important;
					color: @black !important;
					padding: 10px !important;
				}
				input[type=text] {
					margin-bottom: 0 !important;
				}
				.validation_message {
					position: absolute;
				}
			}

			.gform_footer {
				padding: 0 !important;
				margin: 8px 0 0 !important;
				text-align: right;
			}
		}

		button,
		input[type=reset],
		input[type=submit],
		#gform_submit_button_1 {
			border: none;
			cursor: pointer;
			outline: inherit;
			font-family: 'prohibition', sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 1.125rem !important;
			color: @white !important;
			letter-spacing: 0.89px;
			text-align: center;
			line-height: 18px;
			background: @charcoal !important;
			padding: 8px;
			text-decoration: none;
			display: inline-block;
			height: 40px !important;
			.transition(all 1s ease);

			&:hover {
				box-shadow: inset 0 0 0 1px @black, inset 0 0 0 2px #fff, inset 0 0 0 3px @black, inset 0 0 0 4px @gold,
					inset 0 0 0 5px @black, inset 0 0 0 6px #fff;
			}
		}
	}
}

/* News Post Blocks
*/
.news_post {
	.flexJustifyContent(space-between);
	.flexAlignItems(center);
}

/* Content Media Blocks
*/
.content_media_block {
	.flex {
		.flexJustifyContent(center);
		.flexAlignItems(center);
	}
	.media_block {
		max-width: 560px;
		width: 100%;
		.flex(1 0 0);

		img {
			display: block;
			width: 100%;
		}
	}
	.content_block {
		max-width: 500px;
		width: 100%;
		.flex(1 0 0);
		margin-right: 80px;
	}
}
// Video embedds
.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	overflow: hidden;
	max-width: 100%;
	height: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Alternating Block
*/
.alt_block {
	.flex {
		.flexJustifyContent(center);
		.flexAlignItems(center);
	}
	.image_block {
		max-width: 555px;
		width: 100%;
		.flex(1 0 0);

		img {
			display: block;
			width: 100%;
		}
	}
	.content_block {
		max-width: 500px;
		width: 100%;
		.flex(1 0 0);

		img {
			width: 100%;
		}
	}
}
.alt_block:nth-of-type(odd) {
	.content_block {
		margin-left: 80px;
	}
}
.alt_block:nth-of-type(even) {
	.flex {
		.flexDirection(row-reverse);
	}
	.image_block {
		margin-left: 80px;
	}
}

/* Icon Block
*/
.icon_blocks {
	.icon_row_block {
		.flexJustifyContent(space-between);

		.icon_block {
			max-width: 362px;
			width: 100%;
			min-width: 280px;
			.flex(1 0 0);
			margin: 0 auto;
			padding-left: 20px;
			padding-right: 20px;

			.icon_wrapper {
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
				margin: 0 auto;
				width: 136px;
				height: 112px;
				position: relative;

				.icon_img {
					width: 70%;
					max-height: 100px;
					position: absolute;
					top: 50%;
					left: 50%;
					.transform(translate3d(-50%, -50%, 0));
				}
			}
		}
	}

	.title_stylize {
		font-family: 'mrs-eaves-xl-serif', serif;
		font-style: normal;
		font-weight: 700;
		font-size: 64px;
		font-size: 4rem;
		text-shadow: 2px 2px 0 @gold;
		line-height: 72px;
		margin: 0;
		text-align: center;
		color: @charcoal;
	}
	&.bg_white {
		.icon_wrapper {
			background-image: url('../images/icon_bg_grey.svg');
		}
	}
	&.bg_texture {
		.icon_wrapper {
			background-image: url('../images/icon_bg_white.svg');
		}
	}
}

// DUAL MEDIA CTA BLOCKS

/* Icon Block
*/
.dual_media_cta_blocks {
	.media_row_block {
		.flexAlignItems(flex-end);

		.media_cta_block {
			max-width: 490px;
			width: 100%;
			min-width: 280px;
			.flex(1 0 0);
			margin: 0 auto;
			padding-left: 20px;
			padding-right: 20px;

			img {
				max-width: 470px;
				width: 100%;
				display: block;
				margin: 0 auto;
			}
		}
	}

	&.bg_white {
		.icon_wrapper {
			background-image: url('../images/icon_bg_grey.svg');
		}
	}
	&.bg_texture {
		.icon_wrapper {
			background-image: url('../images/icon_bg_white.svg');
		}
	}
}

/// Links with arrow after
.icon_cta_link {
	text-decoration: none;
	color: @black !important;
	position: relative;
	.transition(all .25s ease);

	&:after {
		.fa-icon;
		.fas;
		content: @fa-var-arrow-right;
		margin-left: 5px;
		color: @black;
		border: 2px solid @gold;
		font-size: 10px;
		padding: 3px;
		border-radius: 50%;
		position: absolute;
		top: 0;
		.transition(background .25s ease);
	}
	&:hover {
		color: @charcoal !important;
		&:after {
			background: @gold;
		}
	}
}

/* Future Blocks
*/
.future_blocks {
	overflow: hidden;
}
.milestone_container {
	background-image: url('../images/timelineBg.png');
	background-repeat: repeat-y;
	background-position: top center;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		display: block;
		border: 2px solid @gold;
		width: 64px;
		top: 0;
		margin: 0 auto;
		left: 50%;
		.transform(translate3d(-50%, 0, 0));
	}
	&::after {
		background-image: url(../images/slider_next_icon.svg);
		background-repeat: no-repeat;
		background-size: contain;
		content: '';
		width: 64px;
		height: 48px;
		display: block;
		position: absolute;
		bottom: -3em;
		left: 50%;
		.transform(translate3d(-50%, 0, 0));
	}
}
.future_milestone_wrapper {
	width: 100%;

	.future_milestone_block {
		width: 100%;

		.milestone_item {
			.flexAlignContent(flex-end);
			.transform(translate3d(10%, 0, 0));
			width: 54%;
			padding-bottom: 30px;

			.future_img_wrapper {
				width: 35%;
				position: relative;
				max-width: 400px;
				img {
					display: block;
					width: 100%;
				}
			}
			.content_wrapper {
				width: 55%;
				margin: 0 10% 0 0;
				text-align: right;

				.highlight {
					margin: 0;
					display: inline-block;
					position: relative;
					z-index: 6;
					&::after {
						position: absolute;
						bottom: -16px;
						right: -8px;
						background-image: url('../images/highlight_lines.png');
						background-repeat: repeat-x;
						background-size: contain;
						content: '';
						width: 100%;
						height: 33px;
					}

					h3 {
						margin: 0;
						position: relative;
						z-index: 5;
					}
				}
			}
		}
	}
}
.future_milestone_wrapper:nth-of-type(odd) {
	.future_milestone_block {
		.content_wrapper a {
			left: -5%;
			.transform(translate3d(0, 0, 0));
		}
		.milestone_item {
			.future_img_wrapper {
				&::before {
					content: '';
					position: absolute;
					top: 50%;
					left: 0;
					width: 50%;
					height: 50%;
					.transform(translate3d(-35%, -50%, 0));
					background-image: url('../images/icon_pointer.svg');
					background-repeat: no-repeat;
					background-size: contain;
				}
			}
		}
	}
}

.future_milestone_wrapper:nth-of-type(even) {
	.future_milestone_block {
		.milestone_item {
			margin: 0 0 0 35%;

			.milestone_details {
				.flexDirection(row-reverse);
				.content_wrapper {
					text-align: left;
					margin: 0 0 0 10%;
				}

				.future_img_wrapper {
					&::before {
						content: '';
						position: absolute;
						top: 50%;
						right: 0;
						left: auto;
						width: 50%;
						height: 50%;
						.transform(translate3d(55%, -50%, 0));
						background-image: url('../images/icon_pointer_right.svg');
						background-repeat: no-repeat;
						background-size: contain;
					}
				}
			}
		}
	}
}

/* Support Organizations Block
*/
.organization_slider {
	position: relative;
	padding: 50px 0;
	max-width: 1700px;
	margin: 0 auto;

	.grad-left {
		left: 0;
		top: 0;
		position: absolute;
		z-index: 2;
		height: 100%;
		width: 100px;
		background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
		background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
		background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
	}
	.grad-right {
		right: 0;
		top: 0;
		position: absolute;
		z-index: 2;
		height: 100%;
		width: 100px;
		background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
		background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
		background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
	}

	.slick-initialized .slick-slide {
		display: inline-block !important;
		vertical-align: middle;
		float: none;
	}
	.items {
		.img-cont {
			display: table-cell;
			vertical-align: middle;
			padding: 1em;
			img {
				height: auto;
				display: block;
				margin: 0 3em;
				max-width: 400px;
				max-height: 250px;
			}
		}
	}
}

/* Gallery Blocks
*/
.gallery_images {
	overflow: hidden;

	.slick-slide {
		.opacity(.6);
		.transform(scale(.9));
		filter: gray;
		filter: grayscale(100%);
	}
	.slick-current.slick-active {
		.opacity(1);
		.transform(scale(1));
		filter: none;
		filter: grayscale(0);
	}

	.gallery_image {
		max-width: 945px;
		width: 100%;

		img {
			display: block;
			width: 100%;
			max-width: 945px;
			margin: 0 auto;
		}
	}
}
.gallery_blocks .gallery_slider_container {
	position: relative;
	.paginator {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
	}
	.arrow_container {
		position: relative;
		max-width: 945px;
		width: 100%;
		margin: 0 auto;
		height: 100%;
	}

	.slick-prev.slick-arrow,
	.prev_gallery {
		position: absolute;
		top: 50%;
		left: -3.5%;
		z-index: 9;
		.transform(translate3d(3.5%, -50%, 0));
		padding: 0;
		border: none;
		line-height: 0;
		font-size: 0;
		outline: 0;
		&:before {
			display: none;
		}
		&:after {
			background-image: url('../images/icon_pointer_left_solid.svg');
			background-repeat: no-repeat;
			background-size: contain;
			cursor: pointer;
			content: '';
			width: 48px;
			height: 64px;
			display: block;
		}
	}
	.slick-next.slick-arrow,
	.next_gallery {
		position: absolute;
		top: 50%;
		right: 0;
		z-index: 9;
		.transform(translate3d(0, -50%, 0));
		padding: 0;
		border: none;
		line-height: 0;
		font-size: 0;
		outline: 0;

		&:before {
			display: none;
		}
		&:after {
			background-image: url('../images/icon_pointer_right_solid.svg');
			background-repeat: no-repeat;
			background-size: contain;
			cursor: pointer;
			content: '';
			width: 48px;
			height: 64px;
			display: block;
		}
	}
	.slick-dots {
		position: relative;
		bottom: 0;

		li {
			margin: 10px 5px;
			--fa-primary-color: @white;
			--fa-secondary-color: @white;
			--fa-primary-opacity: .75;
			--fa-secondary-opacity: .75;
			color: @white;
			position: relative;

			button {
				border: 0;
				background: 0 0;
				display: block;
				position: absolute;
				height: 50%;
				width: 50%;
				background: @white;
				.borderRadius(50%);
				margin: auto;
				bottom: 0;
				right: 0;
				left: 0;
				top: 0;
				padding: 0;

				&::before {
					display: none;
				}
			}
			&.slick-active {
				button {
					background: @gold;
				}
			}

			&::before {
				.borderRadius(50%);
				border: 1px solid #ccc;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				font-size: 1.1em;
			}
		}
	}
}
.gallery_blocks {
	.icon_row_block {
		.flexJustifyContent(center);

		.icon_block {
			max-width: 185px;
			width: 100%;
			min-width: 185px;
			.flex(1);
			margin: 0 auto;

			.icon_wrapper {
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
				margin: 0 auto;
				width: 136px;
				height: 112px;
				position: relative;

				.icon_img {
					max-width: 80px;
					// width: 70%;
					max-height: 100px;
					position: absolute;
					top: 50%;
					left: 50%;
					.transform(translate3d(-50%, -50%, 0));
				}
			}
		}
	}

	&.bg_white {
		.icon_wrapper {
			background-image: url('../images/icon_bg_grey.svg');
		}
	}
	&.bg_texture {
		.icon_wrapper {
			background-image: url('../images/icon_bg_white.svg');
		}
	}
}

/* Media Queries
----------------------------*/
@media only screen and (max-width: 1440px) {
	// Alternating Blocks
	.alt_block:nth-of-type(odd) {
		.content_block {
			margin-left: 60px;
		}
	}
	.alt_block:nth-of-type(even) {
		.image_block {
			margin-left: 60px;
		}
	}

	/* Content Media Blocks
	*/
	.content_media_block {
		.content_block {
			margin-right: 60px;
		}
	}
}

@media only screen and (max-width: 1280px) {
	// Alternating Blocks
	.alt_block:nth-of-type(odd) {
		.content_block {
			margin-left: 30px;
		}
	}
	.alt_block:nth-of-type(even) {
		.image_block {
			margin-left: 30px;
		}
	}
	/* Content Media Blocks
	*/
	.content_media_block {
		.content_block {
			margin-right: 30px;
		}
	}

	/* Form Block Newsletter
*/
	.form_blocks {
		.flex {
			.flexAlignItems(center);
			.flexDirection(column);
		}
		#gform_wrapper_1 {
			margin-top: 0;
		}
	}

	.gallery_images {
		.gallery_image {
			max-width: 645px;
		}
	}
	.gallery_blocks .gallery_slider_container {
		.arrow_container {
			max-width: 645px;
		}
	}
}

@media only screen and (max-width: 926px) {
	/* Form Block Newsletter
*/
	.form_blocks {
		.flex {
			.flexAlignItems(center);
		}
		#gform_wrapper_1 {
			margin-top: 0;
			form,
			.gform_fields {
				ul li.gfield {
					margin-top: 0;
					width: 100%;

					span {
						padding-top: 0 !important;
						margin: 0 !important;
					}
					input {
						border: none;
						width: 100% !important;
						min-width: 160px;
						margin: 0;
						height: 40px !important;
						color: @black !important;
						padding: 10px !important;
					}
					input[type=text] {
						margin-bottom: 0 !important;
					}
				}

				.gform_footer {
					padding: 0 !important;
					margin: 10px 0 0 0 !important;
				}
			}
			button,
			input[type=reset],
			input[type=submit] {
				width: 100%;
			}
		}
	}

	// Alternating Blocks
	.alt_block {
		.flex {
			.flexDirection(column);
		}
		.image_block {
			overflow: hidden;
			.flex(1 0 auto);
		}
		.content_block {
			max-width: 555px;
		}
	}

	.alt_block:nth-of-type(odd) {
		.content_block {
			margin-left: 0;
		}
	}
	.alt_block:nth-of-type(even) {
		.flex {
			.flexDirection(column);
		}
		.image_block {
			margin-left: 0;
		}
	}

	/* Content Media Blocks
	*/
	.content_media_block {
		.flex {
			.flexDirection(column);
		}
		.flex.column_reverse {
			.flexDirection(column-reverse);
		}
		.content_block {
			margin-right: 0;
			max-width: 560px;
		}
		.media_block {
			.flex(1 0 auto);
			overflow: auto;
		}
	}

	/* Future Milestone Blocks
	*/
	.milestone_container {
		background-position: 9.5% 0;
		max-width: 500px;
		margin: 0 auto;
		&::before {
			width: 100%;
		}
		&::after {
			bottom: -3em;
			left: 0;
			.transform(translate3d(25.5%, 0, 0));
		}
	}
	.future_milestone_wrapper {
		width: 100%;

		.future_milestone_block {
			width: 100%;

			.milestone_item {
				.flexAlignContent(flex-start);
				.flexJustifyContent(center);
				.transform(translate3d(0, 0, 0));
				max-width: 550px;
				width: 100%;
				.milestone_details {
					max-width: 450px;
					.flexDirection(column-reverse);

					.future_img_wrapper {
						width: 80%;
						margin: 0 0 0 20%;
						overflow: auto;
					}
					.content_wrapper {
						text-align: left;
						margin: 0 0 0 20%;
						width: 80%;

						.highlight {
							position: absolute;
							top: 10%;
							left: 5%;
							.transform(translate3d(-10%, -5%, 0));
						}
					}
				}
			}
		}
	}

	.future_milestone_wrapper:nth-of-type(even) {
		.future_milestone_block {
			.milestone_item {
				margin: 0;

				.milestone_details {
					.flexDirection(column-reverse);

					.future_img_wrapper {
						&::before {
							content: '';
							position: absolute;
							top: 10%;
							left: 0;
							width: 20%;
							height: 20%;
							.transform(translate3d(-25%, 0, 0));
							background-image: url('../images/icon_pointer.svg');
							background-repeat: no-repeat;
							background-size: contain;
						}
					}
					.content_wrapper {
						margin: 0 0 0 20%;
						width: 80%;
					}
				}
			}
		}
	}
	.future_milestone_wrapper:nth-of-type(odd) {
		.future_milestone_block {
			.milestone_item {
				.milestone_details {
					.content_wrapper a {
						left: 0;
						.transform(translate3d(0, 0, 0));
					}
					.future_img_wrapper {
						&::before {
							content: '';
							position: absolute;
							top: 10%;
							left: 0;
							width: 20%;
							height: 20%;
							.transform(translate3d(-25%, 0, 0));
							background-image: url('../images/icon_pointer.svg');
							background-repeat: no-repeat;
							background-size: contain;
						}
					}
				}
			}
		}
	}
	/* Support Organizations Block
	*/
	.organization_slider {
		padding: 25px 0;
	}
}
@media only screen and (max-width: 768px) {
	.gallery_images {
		.gallery_image {
			max-width: 480px;
		}
	}
	.gallery_blocks .gallery_slider_container {
		.arrow_container {
			max-width: 480px;
		}
		.slick-dots li {
			height: 20px;
			width: 20px;
			margin: 5px 5px 10px;
		}
		.slick-prev.slick-arrow,
		.prev_gallery {
			left: -6%;
			.transform(translate3d(6%, -50%, 0));

			&:after {
				width: 36px;
				height: 48px;
			}
		}
		.slick-next.slick-arrow,
		.next_gallery {
			right: -1.5%;
			.transform(translate3d(1.5%, -50%, 0));

			&:after {
				width: 36px;
				height: 48px;
			}
		}
	}
	/* Support Organizations Block
	*/
	.organization_slider {
		padding: 15px 0;
	}
}
@media only screen and (max-width: 730px) {
	/* Form Block Newsletter
	*/
	.form_blocks {
		#gform_wrapper_1 {
			form,
			.gform_fields {
				ul li.gfield {
					input {
						border: none;
						width: 100% !important;
						min-width: 135px;
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 641px) {
	/* Form Block Newsletter
*/
	.form_blocks {
		#gform_wrapper_1 {
			display: block;
			max-width: 500px;
			width: 100%;
			form,
			.gform_fields {
				display: block;
				width: 100%;

				ul li.gfield {
					margin-top: 0;
					width: 100%;
					padding-top: 0;
					padding-bottom: 0;

					span {
						// padding-top: 8px !important;

						&.name_first {
							padding-right: 16px !important;
						}
					}
					.validation_message {
						position: relative;
					}
				}
			}
		}
	}

	.gallery_images {
		.gallery_image {
			max-width: 400px;
		}
	}
	.gallery_blocks .gallery_slider_container {
		.arrow_container {
			max-width: 400px;
		}
	}
	/* Support Organizations Block
	*/
	.organization_slider {
		padding: 0;
	}
}
@media only screen and (max-width: 525px) {
	/* Future Milestone Blocks
	*/
	.future_milestone_wrapper {
		.future_milestone_block {
			.milestone_item {
				.milestone_details {
					.future_img_wrapper {
						width: 70%;
						margin: 0 0 0 30%;
					}
					.content_wrapper {
						margin: 0 0 0 30%;
						width: 70%;
					}
				}
			}
		}
	}
	.future_milestone_wrapper:nth-of-type(even) {
		.future_milestone_block {
			.milestone_item {
				.milestone_details {
					.content_wrapper {
						margin: 0 0 0 30%;
						width: 70%;
					}
				}
			}
		}
	}

	.gallery_images {
		.gallery_image {
			max-width: 360px;
		}
	}
	.gallery_blocks .gallery_slider_container {
		.arrow_container {
			max-width: 360px;
		}
	}
	/* Future Milestone Blocks
	*/
	.milestone_container {
		&::after {
			.transform(translate3d(20.5%, 0, 0));
		}
	}
}

@media only screen and (max-width: 480px) {
	.gallery_images {
		.gallery_image {
			max-width: 290px;
		}
	}
	.gallery_blocks .gallery_slider_container {
		.slick-dots li {
			height: 10px;
			width: 10px;
			margin: 5px 8px 10px;
		}
		.arrow_container {
			max-width: 290px;
		}
		.slick-prev.slick-arrow,
		.prev_gallery {
			left: -6%;
			.transform(translate3d(6%, -50%, 0));

			&:after {
				width: 24px;
				height: 32px;
			}
		}
		.slick-next.slick-arrow,
		.next_gallery {
			right: -3.5%;
			.transform(translate3d(3.5%, -50%, 0));

			&:after {
				width: 24px;
				height: 32px;
			}
		}
	}
	.gallery_blocks {
		.icon_row_block {
			.icon_block {
				max-width: 175px;
				min-width: 140px;
			}
		}
	}
	.milestone_container {
		&::after {
			.transform(translate3d(15.5%, 0, 0));
		}
	}
}
@media only screen and (max-width: 380px) {
	.gallery_blocks .gallery_slider_container {
		.slick-prev.slick-arrow,
		.prev_gallery {
			display: none;
		}
		.slick-next.slick-arrow,
		.next_gallery {
			display: none;
		}
	}
	.milestone_container {
		&::after {
			.transform(translate3d(5.5%, 0, 0));
		}
	}
}
