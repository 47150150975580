/*
	Name:				marios_story.less
	Description:        Mario's Story styles for the theme.
	Version:            1.0.0
	Author:             Garrison Hughes
*/

@import (reference) 'variables';
@import (reference) 'mixins';

/* Mario's Story Core structure elements
----------------------------*/
.marios_story {
	.image_block {
		max-width: 419px;
		width: 100%;
		margin: 0 auto;
		position: relative;
	}
	.mario_img {
		width: 100%;
		.borderRadius(50%);
	}

	.content_block {
		.flex(1);
		margin-left: 70px;

		.signature_img {
			max-width: 235px;
		}
	}
	.team_title {
		color: @silver;
	}
}
.marios_career {
	.content_block {
		.flex(1);
		border-right: 3px dotted @gold;
		padding-right: 80px;
		margin-right: 80px;
	}
	.stats_block {
		.mario_img {
			max-width: 398px;
			width: 100%;
		}
		.main_stat {
			font-size: 1.32rem;
			font-weight: 700;
			// line-height: 1.8;

			.gold {
				color: @gold;
			}
		}
		.support_stat {
			color: @silver;
			font-size: 1.125rem;
		}
	}
}

/* Media Queries
----------------------------*/
@media only screen and (max-width: 987px) {
	.marios_story {
		.flex {
			.flexDirection(column);
		}
		.image_block {
			max-width: 325px;
			width: 100%;
			margin: 0;
			position: relative;
		}
		.content_block {
			.flex(1 0 auto);
			margin-left: 0;
		}
	}

	.marios_career {
		.flex {
			.flexDirection(column);
		}
		.content_block {
			.flex(1 0 auto);
			border-right: none;
			border-bottom: 3px dotted @gold;
			padding-right: 0;
			margin-right: 0;
			padding-bottom: 20px;
			margin-bottom: 40px;
		}
	}
}

/* Media Queries
----------------------------*/
@media only screen and (max-width: 526px) {
	.marios_story {
		.image_block {
			max-width: 225px;
		}
	}
}
