/*
	Name:				inputs.less
	Description:        Inputs, buttons and other form element specific styles for the theme.
	Version:            1.0.0
	Author:             Garrison Hughes
*/

@import (reference) 'variables';
@import (reference) 'mixins';

button,
input[type='submit'],
input[type='reset'] {
	background: none;
	color: inherit;
	border: none !important;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

input[type='file'] {
	padding: 0 !important;
	border: none !important;
}
input,
optgroup,
select,
textarea {
	.borderRadius(0);
	padding: 8px !important;
	border: 1px solid #d4d4d4 !important;
	&:focus {
		outline: 1px solid @gold !important;
	}
}

//Gravity Form overrides

body .gform_wrapper .gform_body .gform_fields .gfield .gfield_label .gfield_required {
	color: red;
}

.gform_button.button {
	font-family: 'prohibition', sans-serif !important;
	font-style: normal;
	font-weight: 400 !important;
	font-size: 1.125rem !important;
	color: @black !important;
	letter-spacing: 0.89px !important;
	line-height: 18px !important;
	background: @gold !important;
	text-align: center;
	padding: 8px;
	text-decoration: none;
	display: inline-block;
	.transition(all 1s ease);

	&:hover {
		box-shadow: inset 0 0 0 1px @gold, inset 0 0 0 2px #fff, inset 0 0 0 3px @gold, inset 0 0 0 4px #000,
			inset 0 0 0 5px @gold, inset 0px 0px 0px 6px #fff;
	}
}
.mlf_form_wrapper .mlf_form .gform_button.button {
	background: @black !important;
	color: @white !important;
	height: 40px !important;

	&:hover {
		box-shadow: inset 0 0 0 1px @black, inset 0 0 0 2px #fff, inset 0 0 0 3px @black, inset 0 0 0 4px @gold,
			inset 0 0 0 5px @black, inset 0 0 0 6px #fff;
	}
}
.gform_wrapper .top_label .gfield_label,
.gform_wrapper legend.gfield_label {
	font-weight: 400 !important;
	font-size: 1.125rem;
}
.form_block .gform_wrapper {
	max-width: 755px !important;
	margin: 16px auto !important;
}
.gform_wrapper {
	position: relative;

	input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
		.borderRadius(0) !important;
		padding: 8px !important;
		// border: 1px solid #d4d4d4 !important;

		&:focus {
			.borderRadius(0) !important;
			border: 1px solid @gold !important;
		}
	}
	// input[aria-required=true] {
	// 	border: 1px solid #d10000;
	// }
}

.gform_wrapper form.mlf_form.gf_simple_horizontal div.gform_body ul.top_label.form_sublabel_below li.gfield {
	vertical-align: top !important;
	position: relative;

	span {
		padding-top: 0;
	}
	input {
		margin-bottom: 0;
		height: 40px !important;
		width: 225px !important;
		color: @black !important;
		padding: 10px !important;
	}
}

.gform_wrapper li.gfield.gfield_error,
.gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
	background: none !important;
	border: none !important;
}
.gform_wrapper div.validation_error {
	border: none !important;
	// position: absolute;
	// top: -10px;
	// padding: 0 !important;
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required label.gfield_label {
	margin-top: 0 !important;
}
.gform_wrapper .field_description_below .gfield_description {
	padding-top: 0 !important;
}

// GF Contact Us
#gform_wrapper_4 .gform_footer {
	text-align: center;
}

@media only screen and (max-width: 926px) {
	.gform_wrapper form.mlf_form.gf_simple_horizontal div.gform_body ul.top_label.form_sublabel_below li.gfield {
		vertical-align: top !important;
		position: relative;

		input {
			width: 100% !important;
		}
	}
}
