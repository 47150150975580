/*
	Name:				subpage_content_blocks.less
	Description:        Subpage Content Blocks styles for the theme.
	Version:            1.0.0
	Author:             Garrison Hughes
*/
@import 'fontawesome/fontawesome';
@import 'fontawesome/regular';
@import 'fontawesome/light';
@import 'fontawesome/solid';
@import 'fontawesome/duotone';

@import (reference) 'variables';
@import (reference) 'mixins';

/* Subpage Core structure elements
----------------------------*/

/* Dual Media Blocks
*/
.dual_media_blocks {
	.flex {
		.flexJustifyContent(space-between);
	}
	.media_block {
		.flex(1 0 45%);
		width: 100%;

		img {
			display: block;
			width: 100%;
		}

		&:nth-of-type(odd) {
			margin-right: 20px;
		}
		&:nth-of-type(even) {
			margin-left: 20px;
		}
	}
}

/* Content Blocks
*/

//checklist
ul.checklist {
	list-style-type: none;
	font-size: 1.125rem;
	padding-inline-start: 15px;

	li {
		margin-bottom: 12px;
		.displayFlex();
		.flexAlignItems(flex-start);
	}

	li::before {
		display: inline-block; /* Needed to add space between the bullet and the text */
		.fa-icon;
		.fas;
		content: @fa-var-check;
		border: 2px solid @gold;
		.borderRadius(50%);
		font-size: 10px;
		padding: 2px;
		margin: 2px 10px 0 0;
	}
}

/* Image/Vertical Slider Blocks
*/

.image_blocks {
	.image_blocks_wrapper {
		position: relative;
		max-width: 755px;
		width: 100%;

		.paginator {
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;

			.slick-arrows-slider {
				height: 100%;
				.displayFlex();
				.flexJustifyContent(space-between);
				.flexDirection(column);
				.flexAlignItems(center);
			}

			.prev:after {
				background-image: url('../images/slider_prev_black_icon.svg');
				background-repeat: no-repeat;
				background-size: contain;
				cursor: pointer;
				content: '';
				width: 64px;
				height: 48px;
				display: block;
			}
			.next:after {
				background-image: url('../images/slider_next_gold_icon.svg');
				background-repeat: no-repeat;
				background-size: contain;
				cursor: pointer;
				content: '';
				width: 64px;
				height: 48px;
				display: block;
			}
			.slick-dots {
				position: relative;
				bottom: 0;

				li {
					display: block;
					font-size: 1.35rem;
					margin: 0 auto;
					height: 100%;
					width: 100%;
					--fa-primary-color: @charcoal;
					--fa-secondary-color: @charcoal;
					--fa-primary-opacity: .75;
					--fa-secondary-opacity: .75;
					color: @charcoal;
					background: @white;
					padding: 4px;

					&:first-child {
						.borderTopLeftRadius(50%);
						.borderTopRightRadius(50%);
					}
					&:last-child {
						.borderBottomLeftRadius(50%);
						.borderBottomRightRadius(50%);
					}
					button {
						// .opacity(0);
						position: absolute;
						height: 35%;
						width: 35%;
						// font-size: 1em;
						background: @charcoal;
						.borderRadius(50%);
						display: block;
						top: 0;
						margin: auto;
						bottom: 0;
						right: 0;
						left: 0;
						padding: 0;
						&::before {
							display: none;
						}
					}
					&.slick-active {
						button {
							background: @gold;
						}
					}
				}
			}
		}
	}
	.image_slider {
		line-height: 0;
	}
	.image_figure figure {
		margin: 0;
		max-width: 755px;
		width: 100%;

		img {
			display: block;
			width: 100%;
		}
	}
}

/* Quote Blocks
*/
.quote_block {
	border: 4px solid @gold;
	padding: 2em;
	position: relative;

	&::before {
		content: ' ';
		background-image: url('../images/icon_quote.svg');
		background-repeat: no-repeat;
		background-size: contain;
		width: 48px;
		height: 40px;
		display: block;
		position: absolute;
		top: -20px;
	}

	.quote_owner.flex {
		.flexAlignItems(center);
	}
	.quote_img {
		.borderRadius(50%);
		border: 1px solid @gold;
		padding: 3px;
		margin-right: 10px;
		max-width: 80px;
	}
}

/* Callout Blocks
*/
.callout_block {
	padding: 1em 2em 2em;
	position: relative;
	background: @smoke;

	&::before {
		content: ' ';
		background-image: url('../images/icon_research.svg');
		background-repeat: no-repeat;
		background-size: contain;
		width: 72px;
		height: 72px;
		display: block;
		position: absolute;
		top: -20px;
		right: 20px;
	}
	.title {
		width: 85%;
	}
}

/* Checkmark Grid Blocks
*/
.check_mark_icon_block {
	.flex(1 1 25%);
	min-width: 120px;
	max-width: 200px;
	padding-right: 10px;

	.icon_check_mark {
		max-width: 56px;
		width: 100%;
		display: block;
	}
}

/* Event Date Blocks
*/
.highlight {
	margin: 0;
	display: inline-block;
	position: relative;
	z-index: 6;
	&::after {
		position: absolute;
		top: 16px;
		left: 0;
		background-image: url('../images/highlight_lines.png');
		background-repeat: repeat-x;
		background-size: auto;
		content: '';
		width: 100%;
		height: 33px;
	}

	h3 {
		position: relative;
		z-index: 5;
		margin-top: 0;
	}
}

@media only screen and (max-width: 768px) {
	/* Dual Media Blocks
*/
	.dual_media_blocks {
		.flex {
			.flexJustifyContent(space-between);
			.flexDirection(column);
		}
		.media_block {
			.flex(1 0 auto);
			max-width: 755px;

			img {
				display: block;
				width: 100%;
			}
			&:nth-of-type(odd) {
				margin-right: 0;
			}
			&:nth-of-type(even) {
				margin-left: 0;
			}
		}
	}

	/* Image/Vertical Slider Blocks
	*/
	.image_blocks {
		.image_blocks_wrapper {
			.paginator {
				.slick-dots {
					li {
						display: block;
						font-size: 1.15rem;
						// margin: 10px 5px;
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 480px) {
	/* Image/Vertical Slider Blocks
*/
	.image_blocks {
		.image_blocks_wrapper {
			.paginator {
				top: -10px;
				.slick-dots {
					li {
						display: block;
						font-size: 1rem;
						// margin: 5px auto;
					}
				}
			}
		}
	}
}
