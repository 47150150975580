/*
	Name:				masthead.less
	Description:        Masthead specific styles for the theme.
	Version:            1.0.0
	Author:             Garrison Hughes
*/
// @import 'fontawesome/fontawesome';
// @import 'fontawesome/regular';
// @import 'fontawesome/light';

@import (reference) 'variables';
@import (reference) 'mixins';
.masthead-blocks {
	background: @black;
	// overflow: hidden;
}

.masthead-feature-container {
	position: relative;
	background: @black;
	display: block !important;
	overflow: hidden;

	.masthead-feature-content {
		position: absolute;
		z-index: 4;
		top: 50%;
		max-width: 370px;
		.transform(translate3d(0, -50%, 0));

		.gold_top::before {
			width: 20%;
		}
		.logo_img {
			width: 100%;
			display: block;
		}
		div div {
			margin-block-start: 1em;
			margin-block-end: 1em;
		}
	}

	.hero_img_container {
		overflow: hidden;
		background-repeat: no-repeat;
		background-size: cover;
		max-height: 550px;
		min-height: 275px;
		background-position: right;
		position: relative;
	}
	.tablet_hero_img,
	.mobile_hero_img {
		display: none;
	}
	.hero_img {
		width: 100%;
		margin: 0 auto;
		display: block;
	}
	.overlay {
		background: black;
		bottom: 0;
		content: '';
		display: block;
		height: 100%;
		width: 55%;
		left: -190px;
		position: absolute;
		right: 0;
		transform: skewX(-29.5deg);
		// transform-origin: 100%;
		.opacity(.75);
	}
}

/* Media Queries
----------------------------*/
@media only screen and (max-width: 1440px) {
	.masthead-blocks {
		.masthead-feature-container {
			.hero_img_container {
				max-height: none;
			}
		}
	}
	.masthead-feature-container {
		.hero_img_container {
			background-image: none;
		}
		.hero_img {
			width: 100%;
			margin: 0 auto;
			visibility: visible;
		}
	}
}
@media only screen and (max-width: 1280px) {
	.masthead-feature-container {
		.masthead-feature-content {
			max-width: 350px;
		}
	}
}

@media only screen and (max-width: 1220px) {
	.masthead-feature-container {
		.hide_tablet {
			display: none;
		}
		.tablet_hero_img {
			display: block;
		}
	}
}

@media only screen and (max-width: 1156px) {
	.masthead-feature-container {
		.masthead-feature-content {
			max-width: 350px;
		}

		.overlay {
			width: 60%;
			left: -190px;
		}
	}
}

@media only screen and (max-width: 1024px) {
}
@media only screen and (max-width: 978px) {
	.masthead-feature-container {
		.masthead-feature-content {
			max-width: 350px;
		}

		.overlay {
			width: 65%;
			left: -190px;
		}
	}
}

@media only screen and (max-width: 900px) {
	.masthead-feature-container {
		.masthead-feature-content {
			max-width: 325px;
		}

		.overlay {
			width: 68%;
			left: -190px;
		}
	}
}

@media only screen and (max-width: 856px) {
	.relative_position {
		position: relative;
	}
	.masthead-feature-container {
		.masthead-feature-content {
			max-width: 100%;
			position: relative;
			padding-bottom: 20px;
			.transform(translate3d(0, 0, 0));

			h1 {
				margin-top: 5px;
			}

			.logo_img {
				width: 100%;
				display: block;
				max-width: 325px;
				margin: 0 auto;
			}
		}

		//update bg position so that incase there are no tablet or mobile images the desktop image is always in view?

		.tablet_hero_img.hero_img_container {
			background-position: center;
			background-size: contain;
			min-height: 100%;
			background-image: none !important;

			.hero_img {
				visibility: visible !important;
			}
		}

		.overlay {
			width: 100%;
			top: -20%;
			left: 0;
			transform: skewY(4deg);
			height: 100%;
			.opacity(1);
		}
	}
}
// @media only screen and (max-width: 800px) {
// 	.masthead-feature-container {
// 		.overlay {
// 			top: 50%;
// 			height: 50%;
// 		}
// 	}
// }

@media only screen and (max-width: 600px) {
	.masthead-feature-container {
		.overlay {
			top: -15%;
		}

		.hide_mobile {
			display: none;
		}
		.mobile_hero_img {
			display: block;
		}
	}
}

@media only screen and (max-width: 480px) {
	.masthead-feature-container {
		.overlay {
			top: -10%;
		}
	}
}
