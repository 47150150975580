/*
	Name:				sidebar.less
	Description:        Sidebar styles for the theme.
	Version:            1.0.0
	Author:             Garrison Hughes
*/
@import 'fontawesome/fontawesome';
@import 'fontawesome/regular';
// @import 'fontawesome/light';
@import 'fontawesome/solid';

@import (reference) 'variables';
@import (reference) 'mixins';

/* Sidebar Core structure elements
----------------------------*/
div.map {
	max-width: 264px;
	height: 190px;
	width: 100%;
}
