/*
	Name:				core.less
	Description:        Core styles for the theme.
	Version:            1.0.0
	Author:             Garrison Hughes
*/

@import (reference) 'variables';
@import (reference) 'mixins';
@import 'normalize';

/* Core structure elements
----------------------------*/

body {
	font-size: 16px;
	font-family: 'mr-eaves-xl-modern', sans-serif;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem;
	min-width: 320px;
	word-break: break-word;
}

#main {
	width: 100%;
	padding: 0 0 0 0;
	margin: 0 auto;
	-webkit-box-shadow: inset 0 10px 21px -12px rgba(0, 0, 0, 0.6);
	-moz-box-shadow: inset 0 10px 21px -12px rgba(0, 0, 0, 0.6);
	box-shadow: inset 0 10px 21px -12px rgba(0, 0, 0, 0.6);
}

#header {
	padding: 0;
	width: 100%;
	margin: 0 auto;
	position: relative;
	z-index: 10;
}

// Pages with sidebar
.subpage_sidebar {
	max-width: 271px;
	min-width: 250px;
	margin-right: 120px;
	.flex(1);
}
.content__page {
	width: 100%;
	max-width: 755px;
	min-width: 500px;
	.flex(1);

	img {
		display: inline-block;
		max-width: 100%;
		height: auto;
	}
}

.content {
	margin: 0 auto;
	display: block;
	max-width: 1440px;
	padding: 0 147px;
	width: 100%;
}

.post_content {
	margin-right: 120px;
	.flex(1);

	//If imgs are added in WYSIWYG
	img,
	.content_blocks img {
		display: inline-block;
		max-width: 100%;
		height: auto;
	}
}
aside.sidebar {
	max-width: 265px;
	.flex(1);
}
.basic_block {
	img {
		display: inline-block;
		max-width: 100%;
		height: auto;
	}
}

/* Common Classes
----------------------------*/
/* Flex
*/
.flex {
	.displayFlex();
	.flexWrap(wrap);
}

/* bg Colors
*/
.bg_black {
	background: @black;
}
.bg_gold {
	background: @gold;
}
.bg_white {
	background: @white;
}

.bg_charcoal {
	background: @charcoal;
}
.bg_silver {
	background: @silver;
}
.bg_gallery {
	background: @gallery;
}
.bg_texture {
	background-image: url('../images/bg_ice.jpg');
	background-size: cover;
}

/* Padding
*/
.xs_padding_top {
	padding-top: 1em;
}
.xs_padding_bottom {
	padding-bottom: 1em;
}
.xs_padding_top_bottom {
	padding-top: 1em;
	padding-bottom: 1em;
}
.sm_padding {
	padding: 2em 1em;
}
.sm_padding_top {
	padding-top: 2em;
}
.sm_padding_bottom {
	padding-bottom: 2em;
}
.sm_padding_top_bottom {
	padding-top: 2em;
	padding-bottom: 2em;
}
.padding_top {
	padding-top: 4em;
}
.padding_bottom {
	padding-bottom: 4em;
}
.padding_top_bottom {
	padding-top: 4em;
	padding-bottom: 4em;
}
.md_padding_top {
	padding-top: 6em;
}
.md_padding_bottom {
	padding-bottom: 6em;
}
.md_padding_top_bottom {
	padding-top: 6em;
	padding-bottom: 6em;
}
.padding_top_remove {
	padding-top: 0;
}
.padding_bottom_remove {
	padding-bottom: 0;
}
.padding_top_bottom_remove {
	padding: 0;
}
/* Body styles
*/
.body_big {
	font-size: 1.75rem;
	line-height: 2rem;
}
.body_md {
	font-size: 1.25rem;
	line-height: 1.5rem;
}
.body_sm {
	font-size: 1rem;
	line-height: 1.5rem;
}
.body_xs {
	font-size: .75rem;
	line-height: 1rem;
}

/* Header Tags
----------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: .67em 0;
}
h1 {
	font-family: 'mrs-eaves-xl-serif', serif;
	font-style: normal;
	font-weight: 700;
	font-size: 2.5rem;
	line-height: 3rem;
}
h2 {
	font-family: 'mrs-eaves-xl-serif', serif;
	font-style: normal;
	font-weight: 700;
	font-size: 2rem;
	line-height: 2.5rem;
}
h3 {
	font-family: 'mrs-eaves-xl-serif', serif;
	font-style: normal;
	font-weight: 700;
	font-size: 1.75rem;
	line-height: 2rem;
}
h4 {
	font-family: 'mrs-eaves-xl-serif', serif;
	font-style: normal;
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 1.75rem;
}
h5 {
	font-family: 'mr-eaves-xl-modern', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.5rem;

	&.gold_text,
	&.gold_text a {
		color: @gold;
		text-transform: uppercase;
		text-decoration: none;
	}
}
h6 {
	font-family: 'mr-eaves-xl-modern', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 1rem;
	line-height: 1.5rem;
}

hr {
	border: 1px solid @gold;
}

// LINKS
a {
	color: #2ea3f2;
	.transition(color .25s ease);

	&:hover,
	&:visited {
		color: #025c9a;
	}
}

//blockquote
blockquote {
	margin: 0;
}

// SPECIAL UL Styles.

.two_column {
	.columnCount(2);
	.columnGap(40px);
}

// Body and text colors
.prohibition {
	font-family: 'prohibition', sans-serif;
	font-style: normal;
	font-weight: 400;
}
.reverse {
	color: @white;
}
.gold {
	color: @gold;
}
.uppercase {
	text-transform: uppercase;
}
.text_center {
	text-align: center;
}
.text_right {
	text-align: right;
}
.text_left {
	text-align: left;
}

.gold_top {
	&::before {
		content: '';
		position: relative;
		display: block;
		border: 2px solid @gold;
		// display: inline-block;
		width: 64px;
		top: 0;
	}
}
.gold_top_center {
	&::before {
		content: '';
		position: relative;
		display: block;
		border: 2px solid @gold;
		width: 64px;
		top: 0;
		margin: 0 auto;
	}
}
.gold_bottom {
	&::after {
		content: '';
		position: relative;
		display: block;
		border: 2px solid @gold;
		// display: inline-block;
		width: 64px;
		bottom: 0;
	}
}
//Btn link styles

.btn_big_gold,
.btn_sm_gold,
.btn_big_white {
	font-family: 'prohibition', sans-serif;
	font-style: normal;
	font-weight: 400;
	text-align: center;
	padding: 8px;
	text-decoration: none;
	display: inline-block;
	.transition(all 1s ease);

	&:hover {
		box-shadow: inset 0 0 0 1px @gold, inset 0 0 0 2px #fff, inset 0 0 0 3px @gold, inset 0 0 0 4px #000,
			inset 0 0 0 5px @gold, inset 0 0 0 6px #fff;
	}
}

.btn_big_gold {
	font-size: 1.125rem;
	color: @black !important;
	letter-spacing: 0.89px;
	line-height: 18px;
	background: @gold;
}
.btn_sm_gold {
	font-size: 14px;
	color: @black !important;
	letter-spacing: 0.78px;
	line-height: 16px;
	background: @gold;
}
.btn_big_white {
	font-size: 1.125rem;
	color: @white !important;
	letter-spacing: 0.89px;
	line-height: 18px;
	background: @charcoal;
}

.btn_big_black {
	font-size: 1.125rem;
	color: @white !important;
	letter-spacing: 0.89px;
	line-height: 18px;
	background: @black;
	font-family: 'prohibition', sans-serif;
	font-style: normal;
	font-weight: 400;
	text-align: center;
	padding: 10px;
	text-decoration: none;
	display: inline-block;
	.transition(all 1s ease);

	&:hover {
		box-shadow: inset 0 0 0 1px @white, inset 0 0 0 2px @black, inset 0 0 0 3px @gold, inset 0 0 0 4px @black,
			inset 0 0 0 5px @gold, inset 0 0 0 6px #fff;
	}
	a {
		color: @white !important;
		text-decoration: none;
	}
}

// #simple-banner{
// 	position: absolute;
// 	top: 120px;
// }
// @media only screen and (max-width: 978px) {
// 	#simple-banner{
// 		top: 72px;
// 	}
// }

/* Media Queries
----------------------------*/
@media only screen and (max-width: 1440px) {
	.content {
		padding: 0 80px;
	}

	.post_content {
		margin-right: 80px;
	}
}

@media only screen and (max-width: 1280px) {
	.content {
		padding: 0 60px;
	}

	.post_content {
		margin-right: 60px;
	}
}
@media only screen and (max-width: 1156px) {
	.content {
		padding: 0 40px;
	}
	// Pages with sidebar
	.subpage_sidebar {
		margin-right: 80px;
	}

	.post_content {
		margin-right: 40px;
	}
}
@media only screen and (max-width: 1024px) {
	.content {
		padding: 0 20px;
	}

	.post_content {
		margin-right: 20px;
	}
}

#simple-banner-mobile {
	display: none;
}

@media only screen and (max-width: 978px) {
	#main {
		margin: 72px auto 0;
	}
	/* ------------------------------------- */
	/* Simple Banner mobile handling 		 */
	/* ------------------------------------- */
	#simple-banner {
		display: none;
	}
	#simple-banner-mobile {
		display: block;
	}
	/* ------------------------------------- */

	.subpage_content.flex {
		.flexDirection(column-reverse);

		.content__page {
			width: 100%;
			max-width: 100%;
			min-width: 100%;
			.flex(1 0 auto);
		}
	}
	.post_block.flex {
		.flexDirection(column);
	}

	// Pages with sidebar
	.subpage_sidebar {
		margin-right: 0;
		border-top: dotted 3px @gold;
		max-width: 755px;
		.flex(1 0 auto);
	}
	.post_content {
		margin-right: 0;
		.flex(1 0 auto);
	}
	aside.sidebar {
		max-width: 100%;
		.flex(1 0 auto);
		overflow: auto;
	}
}

@media only screen and (max-width: 786px) {
	// fix for covid banner
	// #main.alert_banner_present {
	// 	margin: 5px auto 0;
	// }
}

@media only screen and (max-width: 756px) {
	// .content__page {
	// 	width: 100%;
	// 	max-width: 100%;
	// 	min-width: 100%;
	// 	.flex(1);
	// }
	/* Padding
	*/

	.sm_padding_top {
		padding-top: 1.5em;
	}
	.sm_padding_bottom {
		padding-bottom: 1.5em;
	}
	.sm_padding_top_bottom {
		padding-top: 1.5em;
		padding-bottom: 1.5em;
	}
	.padding_top {
		padding-top: 3em;
	}
	.padding_bottom {
		padding-bottom: 3em;
	}
	.padding_top_bottom {
		padding-top: 3em;
		padding-bottom: 3em;
	}
	.md_padding_top {
		padding-top: 5em;
	}
	.md_padding_bottom {
		padding-bottom: 5em;
	}
	.md_padding_top_bottom {
		padding-top: 5em;
		padding-bottom: 5em;
	}
	.padding_scale {
		padding-top: 1em;
		padding-bottom: 1em;
	}
}
@media only screen and (max-width: 480px) {
	.two_column {
		.columnCount(1);
	}

	/* Padding
*/
	.sm_padding_top {
		padding-top: 1em;
	}
	.sm_padding_bottom {
		padding-bottom: 1em;
	}
	.sm_padding_top_bottom {
		padding-top: 1em;
		padding-bottom: 1em;
	}
	.padding_top {
		padding-top: 2em;
	}
	.padding_bottom {
		padding-bottom: 2em;
	}
	.padding_top_bottom {
		padding-top: 2em;
		padding-bottom: 2em;
	}
	.md_padding_top {
		padding-top: 4em;
	}
	.md_padding_bottom {
		padding-bottom: 4em;
	}
	.md_padding_top_bottom {
		padding-top: 4em;
		padding-bottom: 4em;
	}
	.padding_scale {
		padding-top: 0;
		padding-bottom: 0;
	}
}
@media only screen and (max-width: 432px) {
	// fix for covid banner
	// #main.alert_banner_present {
	// 	margin: 0 auto;
	// }
}
