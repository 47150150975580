/*
	Name:				playrooms.less
	Description:        Playroom Location styles for the theme.
	Version:            1.0.0
	Author:             Garrison Hughes
*/
@import 'fontawesome/fontawesome';
@import 'fontawesome/regular';
@import 'fontawesome/light';
@import 'fontawesome/solid';

@import (reference) 'variables';
@import (reference) 'mixins';

/* Playroom Core structure elements
----------------------------*/
.playroom_block {
	max-width: 754px;
	margin: 0 auto;
	padding: 20px 40px;

	// .flex {
	// 	.flexWrap(wrap-reverse);
	// }

	.contact_info {
		.flex(1);
		margin-right: 40px;
	}

	.silver {
		color: @silver;
	}
	.icon {
		display: block;
		&:before {
			.fa-icon;
			.fal;
			font-size: 14px;
			line-height: 1rem;
			position: relative;
			padding: 10px 0;
			color: @gold;
			margin-right: 5px;
		}
	}
	.address-icon {
		&:before {
			content: @fa-var-map-marker-alt;
		}
	}
	.phone-icon {
		&:before {
			content: @fa-var-mobile-android-alt;
		}
	}
	div.map {
		max-width: 262px;
		height: 203px;
		width: 100%;

		img {
			display: block;
			width: 100%;
		}
	}
	.patrons h4 {
		font-size: 1.125rem;
	}
	ul {
		.columnCount(2);
		.columnGap(40px);
	}
}

//Pages that list previews of the playrooms
.playroom_post_block {
	.flexJustifyContent(space-between);
}
.playroom_container {
	max-width: 352px;
	min-width: 300px;
	margin-right: 10px;
	.flex(1);
	width: 100%;

	.playroomTitle {
		margin-bottom: 0;
	}
	img {
		display: block;
		width: 100%;
	}
	.silver {
		color: @silver;
	}
}

.modal_component {
	position: fixed;
	background: @gold;
	top: 0;
	height: 100vh;
	width: 100%;
	z-index: 999;
	left: 0;
	.opacity(0);
	display: none;
	.transition(all, 1s, ease);
	overflow: scroll;

	.content {
		max-width: 829px;
		width: 100%;
		padding: 0 10px 20px;
		top: -5%;
		.transform(translate3d(0, 5%, 0));
	}
	.modal_close {
		text-align: right;
		font-size: 1.5rem;
		color: @black;
		i {
			padding: 10px;
			cursor: pointer;
		}
	}

	.playroom_block {
		background: white;
		max-width: 754px;
		width: 100%;
		margin: 0 auto;
	}

	&.show {
		display: block;
		.opacity(1);
	}
}
@media only screen and (max-width: 675px) {
	.playroom_block {
		padding: 20px;
		.flex {
			.flexDirection(column-reverse);
		}
		.contact_info {
			margin-right: 0;
		}
		div.map {
			max-width: 100%;
		}
	}
}
@media only screen and (max-width: 560px) {
	.playroom_block {
		ul {
			.columnCount(1);
		}
	}
}
@media only screen and (max-width: 480px) {
	.playroom_container {
		min-width: 100%;
		margin-right: 0;
	}

	.modal_component {
		height: 100%;
		.content {
			top: -1%;
			.transform(translate3d(0, 1%, 0));
		}
	}
}
