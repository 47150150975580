/*
	Name:				our_team.less
	Description:        Our Team styles for the theme.
	Version:            1.0.0
	Author:             Garrison Hughes
*/

@import (reference) 'variables';
@import (reference) 'mixins';

/* Our Team Single Page Core structure elements
----------------------------*/
.our_team_single .our_team_container {
	.team_content {
		.flex(1);
		max-width: 670px;
		padding: 0 0 0 70px;
	}
	.team_img_lg {
		max-width: 400px;
		width: 100%;
	}
}
/* Our Team Core structure elements
----------------------------*/
.team_block .our_team_container {
	max-width: 362px;
	padding: 0 20px;
}
.our_team_container {
	width: 100%;
	margin: 0 auto;

	.team_img {
		max-width: 208px;
		width: 100%;
		margin: 0 auto;
		position: relative;
	}
	img {
		width: 100%;
		.borderRadius(50%);
	}
	.team_title {
		color: @silver;
	}
}
